<div class="form-wrapper">
    <div class="text-data">
        <div class="welcome-data">
            <p class="welcome">Welcome To</p>
            <img class="logo" src="assets/images/ff-logo.png">
            <p class="tag-line">Making Logistics your competitive Advantage!</p>
        </div>
    </div>
    <div class="form-data">
        <div class="form-box">

            <div class="">
             
                <h2 class="heading-data">Update Password</h2>
                <form [formGroup]="updatePasswordForm" (submit)="submitUpdatePassword($event)">
                    <p class="email-send-text">Please enter the OTP sent to
                        <span class="email">{{this.email}} </span>
                        <a class="change-email pointer" (click)="goToReset()">CHANGE</a>
                    </p>


                  
                    <div class="top-margin-20">

                        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                        <div class="ff_flex otp-verfication-screen" *ngIf="otpSent ">
                        <span class="timer" *ngIf="!!otpInterval"> {{remainingTime?remainingTime:'00:00'}}</span>
                        <a [ngClass]="!otpInterval ?'resend-active':'resend-inactive'" class="pointer "
                            (click)="!otpInterval ?sendOtp():''">Resend OTP</a>
                        </div>
                        
                    </div>
                    <div class="clearfix"></div>
                    <div class="login-form top-margin-15">
                        <mat-form-field class="col-md-12 no-padding height-50">
                            <input matInput autocomplete="false" [type]="show ? 'text' : 'password'"
                                placeholder="Enter New Password" formControlName="password">
                            <span matSuffix class="pass-show-hide pointer" (click)="show = !show"
                                [attr.aria-label]="'Show password'"
                                [attr.aria-pressed]="show">{{!show?'Show':'Hide'}}</span>
                        </mat-form-field>
                        <mat-error *ngIf="updatePasswordForm.get('password').errors?.required && formSubmitted"
                            class="text-danger">
                            Password is
                            required</mat-error>
                    </div>

                    <div class="clearfix"></div>

                    <div class="login-form top-margin-15">
                        <mat-form-field class="col-md-12 no-padding height-50">
                            <input matInput autocomplete="off" [type]="showRepassword ? 'text' : 'password'"
                                placeholder="Re-Enter Password" formControlName="repassword">
                            <span matSuffix class="pass-show-hide pointer" (click)="showRepassword = !showRepassword"
                                [attr.aria-label]="'Show password'"
                                [attr.aria-pressed]="showRepassword">{{!showRepassword?'Show':'Hide'}}</span>
                        </mat-form-field>
                        <mat-error *ngIf="updatePasswordForm.get('repassword').errors?.required && formSubmitted"
                            class="text-danger">
                            Re-Password is
                            required</mat-error>
                        <mat-error
                            *ngIf="updatePasswordForm.get('password').value && updatePasswordForm.get('repassword').value && (updatePasswordForm.get('password').value != updatePasswordForm.get('repassword').value)"
                            class="text-danger">
                            Password and Re-Password should be same
                        </mat-error>
                    </div>
                    <div class="clearfix"></div>

                   

                    <button class="btn login-via-otp-btn top-margin-40" (click)="updatePassword()">Update
                        Password</button>
                    <p class="switch-to-signup">Remember your password? <a (click)="goTologin()"
                            class="pointer create-account">Continue</a></p>

                </form>


            </div>

        </div>
    </div>
</div>