import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TransporterService } from 'src/app/transporter/transporter.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-with-password',
  templateUrl: './login-with-password.component.html',
  styleUrls: ['./login-with-password.component.scss']
})
export class LoginWithPasswordComponent implements OnInit {
  completed: boolean = false;
  formSubmitted:boolean = false;
  show:boolean = false;
  redirectUrl: any = '';
  gstin: any;
  currentuserdata: any;
  public loginForm = this.fb.group({
    username: ['', [Validators.required,
    Validators.pattern('^(.+@.+([.].+)+)|([1-9][0-9]{9})$')]],
    password: ['',Validators.required]

  });

  constructor(
    public fb: UntypedFormBuilder, 
    private authService: AuthService,
    private transporterService: TransporterService,
    private activatedRoute: ActivatedRoute,
    private router: Router, 
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }


  login(){
    this.formSubmitted = true;
    this.completed = true;
    let username = this.loginForm.value.username.toLowerCase();
    let password = this.loginForm.get('password').value;
    const payload = {
      username: username,
      password: password
    }
    this.authService.login(payload).subscribe((loginRes: any) => {
      this.authService
        .decrytToken()
        .pipe(
          catchError((error: any) => {
            let loginError = error.error.error ? error.error.error : error.error;
            this.completed = false;
            return throwError(error);
          }),
          finalize(() => {
          })
        )
        .subscribe(decrytResponse => {
          this.currentuserdata = decrytResponse;
          if (this.authService.getAuthorizationToken()) {
            let user = this.authService.getUser();
            this.gstin = !!user ? user.organization.id : null;
            // user
            if (environment.production) {
              const amplitude = require('amplitude-js');
              amplitude.getInstance().init("c266ada641154aeabacbcee96bf9238d");
              amplitude.logEvent('login page');
              amplitude.getInstance().setUserId(user.email);
              amplitude.getInstance().setUserProperties({ 'org_type': user.organization.type.name });
              amplitude.getInstance().setUserProperties({ 'org_name': user.organization.name });
            }

            // putting the search() function here directly here
            this.authService.getAccessModule()
              .pipe(
                catchError((error: any) => {
                  return throwError(error);
                }),
                finalize(() => {
                })
              )
              .subscribe(accessModuleResponse => {
                // accessModuleResponse
                localStorage.setItem("defaultLandingModule", accessModuleResponse.landingModule);
                localStorage.setItem("accessGroupData", JSON.stringify(accessModuleResponse.userModuleAccessGroups));
                localStorage.setItem("userModules", JSON.stringify(accessModuleResponse));
                //1 Super;2 Shipper;3 Carrier
                let userType = !!user ? user.organization.type.id : null;
                if (userType == 2) {
                  if (this.redirectUrl) {
                    this.completed = false;
                    let url = this.redirectUrl.toString();
                    let pathname = new URL(url).pathname;
                    if (pathname.includes("/manufacturer")) {
                      this.router.navigate([`${pathname}`]);
                    }
                    else {
                      this.goToMfgProfile(accessModuleResponse.landingModule);
                    }

                  } else if (accessModuleResponse.landingModule) {
                    this.completed = false;
                    //accessModuleResponse.landingModule
                    this.goToMfgProfile(accessModuleResponse.landingModule);

                  }
                  else {
                    this.completed = false;
                    this.router.navigate(["/manufacturer/manage-organization"]);
                  }

                }
                else if (userType == 3) {
                  if (this.redirectUrl) {
                    this.completed = false;
                    let url = this.redirectUrl.toString();
                    let pathname = new URL(url).pathname;

                    if (pathname.includes("/transporter")) {
                      this.router.navigate([`${pathname}`]);
                    }
                    else {
                      this.getTransporterDetails(this.gstin, accessModuleResponse.landingModule);
                    }
                  }
                  else {
                    this.getTransporterDetails(this.gstin, accessModuleResponse.landingModule);
                  }

                  //this.router.navigate(["/transporter/bids"]);
                }
                else if (userType == 1) {
                  if (this.redirectUrl) {
                    this.completed = false;
                    let url = this.redirectUrl.toString();
                    let pathname = new URL(url).pathname;

                    if (pathname.includes("/admin")) {
                      this.router.navigate([`${pathname}`]);
                    }
                    else {
                      this.router.navigate(['/admin/transporters']);
                    }
                  }
                  else {
                    this.completed = false;
                    this.router.navigate(['/admin/transporters']);
                  }
                }
                else {
                  this.completed = false;
                  this.router.navigate(['/login']);
                }
              });
            return false;

          }
          else {
            this.router.navigate(['/login']);
          }


        });

    }, error => {
      this.completed = false  ;
      let loginError = error.error.error ? error.error.error : error.error;
      this._snackBar.open(`${loginError}`, 'OK', {
        duration: 7000,
        panelClass: ['auth-error-snack-bar']
      });
    });

  }

  reset() {
    this.authService.logout()
    .subscribe(data => {
    });
    this.router.navigate(['/forgot-password']);
  }

  goToMfgProfile(landingModule) {
    this.authService.goToMfgLandingPage(landingModule);
  }

  getTransporterDetails(gstin, landingModule) {
    this.transporterService
      .getOnboardingData(gstin)
      .pipe(
        catchError((error: any) => {
          this.router.navigate(['/transporter/onboarding/' + gstin]);
          // this.router.navigate(['/transporter/bid/event']);
          this.completed = true;
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        if (data === undefined || !data["isApproved"]) {
          this.completed = true;
          this.router.navigate(['/transporter/onboarding/' + gstin]);
        }
        else {
          this.completed = true;
          this.goToTsp(landingModule);
        }
      });
  }

  goToTsp(landingModule) {
    this.authService.goToTspLandingModule(landingModule);
  }

  submitLogin(event) {
    if (event.keyCode == 13) {
        this.login();
      }
  }

}
