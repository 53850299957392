import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/internal/operators';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  rbacUrl = environment.rbacBaseUrl;
  prodUrl = environment.apiBaseUrl;
  errorData: {};
  userAccessableModulesSubject = new BehaviorSubject<string>('initial value');

  constructor(
    private router: Router,
    private http: HttpClient, 
    private storage: StorageService
  ) { 
    let accessGroupData = sessionStorage.getItem('onhibernate') == 'true'?sessionStorage.getItem('userModules'):localStorage.getItem('userModules');
    accessGroupData = JSON.parse(accessGroupData);
    this.userAccessableModulesSubject.next(accessGroupData);
  }

  redirectUrl: string;

  updatePassword(email: string, password: string, otp: number): Observable<[string]> {
    const params: any = {};
    var apiData = {
      "username": email,
      "password": password,
      "otp": otp
    }
    let headers = new HttpHeaders();
    return this.http.patch<any>(this.rbacUrl + 'rbac/api/v1/user/password', apiData, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  signup(email: string, pan: string, name: string, password: string, mobile: string): Observable<[string]> {
    const params: any = {};
    var loginData = {
      "email": email,
      "pan": pan,
      "name": name,
      "password": password,
      "mobileNumber": mobile
    }
    let headers = new HttpHeaders();
    return this.http.post<any>(this.rbacUrl + 'rbac/api/v1/transporter/user', loginData, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  login(payload): Observable<[string]> {
    const params: any = {};
    let headers = new HttpHeaders();
    return this.http.post<any>(this.rbacUrl + 'rbac/api/v1/login', payload, { headers: headers })
      .pipe(
        map(
          (data) => {
            let dataToCache = data.token;
            this.storage.setItem('access-token', dataToCache, null);
            return dataToCache;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }
  
  loginWithMfa(email: string, password: string): Observable<[string]> {
    const params: any = {};
    var loginData = {
      "username": email,
      "password": password
    }
    let headers = new HttpHeaders();
    return this.http.post<any>(this.rbacUrl + 'rbac/api/v1/mfa/verify', loginData, { headers: headers })
      .pipe(
        map(
          (data) => {
            let dataToCache = data.token;
            this.storage.setItem('access-token', dataToCache, null);
            return dataToCache;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  decrytToken(): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.rbacUrl + 'rbac/api/v1/token/decrypt', {
          headers: options
        })
        .pipe(
          map(data => {
            this.configureApp(data);
            let dataToCache = data;
            if(sessionStorage.getItem('onhibernate') != 'true'){
              this.storage.setItem('user', dataToCache, null);
            }
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  configureApp(data) {
    if (data) {
      if (data.organization.type.name === 'Super') {
        this.setApp('admin');
      }
      if (data.organization.type.name === 'Shipper') {
        this.setApp('mfg');
      }
      if (data.organization.type.name === 'Carrier') {
        this.setApp('tsp');
      }
    }
    else {
      this.logout()
      .subscribe(data => {
      });
    }
  }

  setApp(app: string): void {
    if (app) {
      if (sessionStorage.getItem('onhibernate') == 'true') {
        sessionStorage.setItem('app', app);
      }
      else{
        sessionStorage.removeItem('app');
        localStorage.setItem('app', app);
      }
    } else {
      sessionStorage.removeItem('app');
      localStorage.removeItem('app');
    }
  }

  get app(): string {
    let appData = '' ;
    if (sessionStorage.getItem('onhibernate') == 'true') {
      appData = sessionStorage.getItem('app');
    }
    else{
      appData = localStorage.getItem('app');
    }
    return appData;
  }

  sendOTP(email: string): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.get<any>(this.rbacUrl + `rbac/api/v1/user/${email}/otp/password-reset`, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  sendLoginOTP(email: string): Observable<[any]> {
    let headers = new HttpHeaders();
    return this.http.get<any>(this.rbacUrl + `rbac/api/v1/user/${email}/otp/login`, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;

  }

  saveManufacturerUsers(id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${id}/user`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  goToMfgLandingPage(landingModule){
    switch (landingModule) {
      case 'MFG-RFQ':
        this.router.navigate(["/manufacturer/rfq"]);
        break;
      case 'MFG-FRT_PROCURE':
        this.router.navigate(["/manufacturer/bids"]);
        break;
      case 'MFG-ANALYTICS':
        this.router.navigate(["/manufacturer/analytics"]);
        break;
      case 'MFG-FRT_IQ':
        this.router.navigate(["/manufacturer/freight-Intelligence"]);
        break;
      case 'MFG-INDENT_MGMT':
        this.router.navigate(["/manufacturer/indent-management"]);
        break;
      case 'MFG-INDENT_MGMT_V2':
        this.router.navigate(["/manufacturer/indent-management/v2"]);
        break;
      case 'MFG-INVOICE_PAYMENT':
        this.router.navigate(["/manufacturer/invoicing-payments"]);
        break;
      case 'MFG-TPT_PERFORMANCE':
        this.router.navigate(["/manufacturer/indent-management"]);
        break;
      case 'MFG-TPT_POOL':
        this.router.navigate(["/manufacturer/transporters"]);
        break;
      case 'MFG-TPT_FINANCIAL_RISK_PROFILE':
        this.router.navigate(["/manufacturer/indent-management"]);
        break;
      case 'MFG-CONTRACT_MGMT':
        this.router.navigate(["/manufacturer/contract-management"]);
        break;
      case 'MFG-SETTINGS':
        this.router.navigate(["/manufacturer/manage-organization"]);
        break;
      case 'MFG-STAR_DASHBOARD':
        this.router.navigate(["/manufacturer/green-logistics"]);
        break;
      case 'MFG-INDENT_MGMT_GATE':
        this.router.navigate(["/manufacturer/vehicle-inwarding"]);
        break;
      case 'MFG-SECONDARY_TRIP_MGMT':
        this.router.navigate(["/manufacturer/secondary-trip-management"]);
        break;
      case 'MFG-VGMS':
        this.router.navigate(["/manufacturer/vehicle-gate-management"]);
        break;
      default:
        this.router.navigate(["/manufacturer/manage-organization"]);
        break;
    }
  }

  goToTspLandingModule(landingModule){
    switch (landingModule) {
      case 'TSP-RFQ':
        this.router.navigate(["/transporter/rfq"]);
        break;
      case 'TSP-FRT_PROCURE':
        this.router.navigate(["/transporter/bid/event"]);
        break;
      case 'TSP-INDENT_MGMT':
        this.router.navigate(["/transporter/indents"]);
        break;
      case 'TSP-INVOICE_PAYMENT':
        this.router.navigate(["/transporter/invoicing-payments"]);
        break;
      case 'TSP-MY_PERFORMANCE':
        this.router.navigate(["/transporter/my-performance"]);
        break;
      case 'TSP-CONTRACT_MGMT':
        this.router.navigate(["/transporter/rfq"]);
        break;
      case 'TSP-SETTINGS':
        this.router.navigate(["/transporter/settings"]);
        break;
      default:
        this.router.navigate(["/transporter/indents"]);
        break;
    }
  }

  updateManufacturerUsers(orgid, id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .put<any>(`${this.rbacUrl}rbac/api/v1/org//${orgid}/user/${id}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteManufacturerUsers(orgid, id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .delete<any>(`${this.rbacUrl}rbac/api/v1/org/${orgid}/user/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerUsers(id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${id}/users/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  getManufacturerprimaryUsers(id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.rbacUrl}rbac/api/v1/manufacturer/${id}/primary-contacts`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  isLoggedIn() {
    if (this.storage.getItem('access-token', false)) {
      return true;
    }
    return false;
  }

  getAuthorizationToken() {

    let accessToken;
    if (sessionStorage.getItem('onhibernate') == 'true') {
      accessToken = sessionStorage.getItem('access-token');
      
    }
    else {
      accessToken = this.storage.getItem('access-token', false);
      
    }

    return accessToken;
  }

  getUser() {
    let accessToken;
    if (sessionStorage.getItem('onhibernate') == 'true') {
      accessToken = sessionStorage.getItem('user');
      accessToken = JSON.parse(accessToken);
    }
    else {
      accessToken = this.storage.getItem('user', false);
    }
    return accessToken;
  }

  getLocalUser() {
    let accessToken;
    accessToken = this.storage.getItem('user', false);
    
    return accessToken;
  }


  getAccessModule() {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/modules/_search`, {}, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            this.userAccessableModulesSubject.next(data);
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getUserAccessControl(userId){
    return new Promise( resolve => {
      this.fetchUserAccessControl(userId)
        .pipe(
          catchError( error => {
            resolve([]);
            return throwError(error);
          })
        )
        .subscribe( resp => {
          resolve(resp);
        });
    });
  }

  fetchUserAccessControl(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = this.storage.getItem('user', false);
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.rbacBaseUrl}rbac/api/v1/user/${id}/access-controls`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getCredentialType(bodyData){
    let options = new HttpHeaders()
    return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/user`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
  }

  //Get site modules for the MFG organization
  getSiteModules(orgId){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      //rbac/api/v1/org/:orgId/site-modules
      return this.http
        .get<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/site-modules`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get MFG organization role
  getMfgRole(orgId, data) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/org/:orgId/roles/_search
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/roles/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get data set ids to assign to users
  getRbacDatasetIds(orgId) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/datasets/_search`, {}, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getOrgTypeBasedRoles(payload){
    // {{rbac_host}}/rbac/api/v1/org-type/role/_search
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org-type/role/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create MFG organization role
  createMfgRole(orgId, data) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/org/:orgId/role
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/role`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateRbacRoleDataset(roleId, payload){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/role/${roleId}/dataset-permissions`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  editMfgRole(orgId, data, id) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/org/:orgId/role
      return this.http
        .put<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/role/${id}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getSideModuelFeatureList(payload) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/site-module/features/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get MFG organization user
  getMfgUsers(orgId, data) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/org/:id/users/_search
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/users/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create MFG organization user
  createMfgUser(orgId, data) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/org/:id/user
      return this.http
        .post<any>(`${this.rbacUrl}rbac/api/v1/org/${orgId}/user`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Update MFG organization user
  updateMfgUser(userId, data) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/user/:userId
      return this.http
        .put<any>(`${this.rbacUrl}rbac/api/v1/user/${userId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Remove MFG organization user
  removeMfgUser(userId) {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/user/:userId
      return this.http
        .delete<any>(`${this.rbacUrl}rbac/api/v1/user/${userId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getUserToken(userId){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rbac/api/v1/user/:userId
      return this.http
        .get<any>(`${this.rbacUrl}rbac/api/v1/user/${userId}/token`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getPrivayPolicy(){
    let headers = new HttpHeaders();
    return this.http.get<any>(this.prodUrl + `onboarding/api/v1/privacy-policy`, { headers: headers })
      .pipe(
        map(
          (data) => {
            return data;
          }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

  verifyNewUser(token: string): Observable<number> {  // Observable returns number (status code)
    let headers = new HttpHeaders();
      return this.http
        .get<any>(this.rbacUrl + `rbac/api/v1/user/verification?vfLink=${token}`, {
          headers: headers
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
  }

  loginUsingMagicLink(token){
    let headers = new HttpHeaders();
      return this.http
        .get<any>(this.rbacUrl + `rbac/api/v1/login/magic-link?magicLink=${token}`, {
          headers: headers
        })
        .pipe(
          map(data => {
            let dataToCache = data.token;
            this.storage.setItem('access-token', dataToCache, null);
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
  }


  logout() {
    var sessiontoken = this.storage.getItem('access-token', false);
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http.post<any>(`${this.rbacUrl}rbac/api/v1/logout`, {
          headers: options
        })
        .pipe(
          map(data => {
          
            if (environment.production) {
              const amplitude = require('amplitude-js');
              amplitude.getInstance().init("c266ada641154aeabacbcee96bf9238d");
              amplitude.logEvent('Session Logout');
            }
            localStorage.removeItem('NewUser');
            this.storage.removeItem('access-token');
            this.storage.removeItem('user');
            this.storage.removeItem('accessGroupData');
            this.storage.removeItem('showProfile');
            this.router.navigate(['/login']);
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          })
        )as Observable<any>;

  }


  private handleError(error: HttpErrorResponse) {
    this.isValidToken(error);
    if (error.status === 500) {
      return throwError('Internal Server Error');
    }else{
      return throwError(error);
    }
  }

  private isValidToken(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.storage.removeItem('access-token');
      this.storage.removeItem('user');
      this.router.navigateByUrl('/login');
    }
  }

}

