import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, catchError } from 'rxjs/internal/operators';
import { throwError } from 'rxjs';
import { TransporterService } from 'src/app/transporter/transporter.service';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  activecls: any = 'login';
  otp: string;
  loginSubmitted: boolean = false;
  signUpSubmitted: boolean = false;
  public show: boolean = false;
  public showRepassword: boolean = false;
  usernameSubmitted: boolean = false;
  config: any = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  userData: any;
  showUsernameError: boolean = false;
  isUserVerificationMode: boolean = false;
  isMagicLinkMode: boolean = false;
  userVerificationInfo: string = 'User verification in progress';
  userVerificationToken: string = undefined;

  otpSent: boolean = false;
  resendOtpFlag: boolean = false;
  otpInterval: any;
  remainingTime: any;
  completed: boolean = true;
  accessGroupData: any;
  redirectUrl: any = '';
  public loginForm = this.fb.group({
    username: ['', [Validators.required,
    Validators.pattern('^(.+@.+([.].+)+)|([1-9][0-9]{9})$')]],
    loginUsing: ['otp'],
    password: ['', Validators.required]

  });

  public signUpForm = this.fb.group({
    email: ['', [Validators.required,
    Validators.pattern('^.+@.+([.].+)+$')]],
    pan: ['', [Validators.required,
    Validators.pattern('^[A-Za-z]{3}(c|C|p|P|h|H|f|F|a|A|t|T|b|B|l|L|j|J|g|G)[A-Za-z][0-9]{4}[A-Za-z]{1}$')]],
    mobile: ['', [Validators.required, Validators.pattern('^[1-9][0-9]{9}$')]],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
    termsAccepted: [false, Validators.required]
  });

  public otpVerificationForm = this.fb.group({
    otp: ['', Validators.required]
  });


  currentuserdata: any;
  gstin: any;

  constructor(public fb: UntypedFormBuilder,
    private authService: AuthService,
    private transporterService: TransporterService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router, private _snackBar: MatSnackBar, private storage: StorageService
  ) {
    this.signUpForm.get('termsAccepted')?.valueChanges.subscribe(value => {
      this.toggleTermsAcceptance(value);
    });
  }

  ngOnInit() {
    this.redirectUrl = history.state.downDocLink;
    const currentUrl = this.router.url;
    if (currentUrl.includes('signup/verify')){
      this.isUserVerificationMode = true;
    }
    if (currentUrl.includes('login/verify')){
      this.isMagicLinkMode = true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      let approvalPage = params['approved'];
      if (approvalPage && approvalPage === 'true') {
        this._snackBar.open(`Thank you for verifying your email. Please login to continue.`, 'OK', {
          duration: 7000,
          panelClass: ['auth-success-snack-bar']
        });
      }
      if(this.isUserVerificationMode){
        this.userVerificationToken = params['token'];
        if(!this.userVerificationToken){
          this.userVerificationInfo = "Invalid user verfication link";
        }else{
          this.userVerificationToken = encodeURIComponent(this.userVerificationToken);
          this.openTermsAndConditionsPopup();
        }
      }
      if(this.isMagicLinkMode){
        this.userVerificationToken = params['token'];
        if(!this.userVerificationToken){
          this.userVerificationInfo = "Invalid login link";
        }else{
          this.userVerificationToken = encodeURIComponent(this.userVerificationToken);
          this.loginUsingMagicLink();
        }
      }
    });
    setTimeout(() => {
      document.getElementById('emailInput').focus();
    }, 1);
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  switchTab(tab) {
    this.activecls = tab;
    clearInterval(this.otpInterval);
    this.otpInterval = '';
    this.remainingTime = '';
    this.loginSubmitted = false;
    this.otpSent = false;
    this.signUpSubmitted = false;
    this.usernameSubmitted = false;
    this.loginForm.reset();
    this.signUpForm.reset();
    this.userData = '';
  }

  resendOTP() {
    this.loginViaOTP();
  }

  goBack() {
    this.loginForm.reset();
    clearInterval(this.otpInterval);
    this.otpInterval = '';
    this.remainingTime = '';
    this.loginSubmitted = false;
    this.usernameSubmitted = false;
    this.otpSent = false;
    this.userData = '';
    this.setFocusToEmail();
  }

  usernameChange() {
    this.loginForm.get('username').patchValue(this.loginForm.get('username').value.trim());
    this.loginForm.controls['password'].markAsUntouched();
    this.loginSubmitted = false;
    this.showUsernameError = true;
    this.userData = '';
    clearInterval(this.otpInterval);
    this.otpInterval = '';
    this.remainingTime = '';
    this.otpSent = false;
    if (this.loginForm.get('username').valid) {
      let body = {
        "credential": this.loginForm.get('username').value
      }
      this.authService.getCredentialType(body)
        .subscribe((data) => {
          this.usernameSubmitted = true;
          this.userData = data;
          var re = new RegExp("^([1-9][0-9]{9})$");

          if (re.test(this.loginForm.get('username').value)) {
            this.userData.credentialType = 'PHONE_NUMBER';
          } else {
            this.userData.credentialType = 'EMAIL';
          }
          if (this.userData['loginModes'] != null && this.userData['loginModes'].includes('OTP')) {
            this.loginForm.get('loginUsing').patchValue('otp');
            this.loginViaOTP();
          }
          else if (this.userData['loginModes'] != null && this.userData['loginModes'].includes('MFA')) {
            this.loginForm.get('loginUsing').patchValue('mfa');
            this.setFocusToEncy();
          } 
          else if(this.userData['loginModes'] != null && this.userData['loginModes'].includes('MAGIC_LINK')){
            this.loginForm.get('loginUsing').patchValue('magic');
            this.sentMagicLink(this.loginForm.value.username);
          }
          else {
            this.loginForm.get('loginUsing').patchValue('');
            this.setFocusToEncy();
          }
        }, error => {
          let loginError = error.error.error ? error.error.error : error.error;
          this._snackBar.open(`${loginError}`, 'OK', {
            duration: 7000,
            panelClass: ['auth-error-snack-bar']
          });

        });
    }
  }

  sentMagicLink(email){
    const payload = {
      "username": email,
    }
    this.authService.login(payload)
      .subscribe((loginRes: any) => {
        
      }, error => {
        let loginError = error.error.error ? error.error.error : error.error;
        this._snackBar.open(`${loginError}`, 'OK', {
          duration: 7000,
          panelClass: ['auth-error-snack-bar']
        });
      });
  }

  setFocusToEmail(){
    setTimeout(() => {
      document.getElementById('emailInput').focus();
    }, 1);
  }
  
  setFocusToEncy(){
    setTimeout(() => {
      document.getElementById('encyInput').focus();
    }, 1);
  }
  
  setFocusToOtp(){
    setTimeout(() => {
      document.getElementById('otpInput').focus();
    }, 1);
  }

  loginViaOTP() {
    if (!this.loginForm.get('username').valid) {
      this.showUsernameError = true;
    }

    if (this.loginForm.get('username').valid) {
      this.remainingTime = '';
      this.authService.sendLoginOTP(this.loginForm.value.username)
        .subscribe((data) => {
          this._snackBar.open(`OTP has been sent successfully to ${this.loginForm.value.username}`, 'OK', {
            duration: 7000,
            panelClass: ['auth-success-snack-bar']
          });
          this.otpSent = true;
          let startTimer = 30;
          this.remainingTime = "00:" + ("" + startTimer).padStart(2, '0');
          this.setFocusToOtp();
          this.otpInterval = setInterval(() => {
            this.remainingTime = "00:" + ("" + startTimer).padStart(2, '0');
            startTimer--;
            if (startTimer <= 0) {
              clearInterval(this.otpInterval);
              this.otpInterval = null;
            }
          }, 1000);
        }, error => {
          let errorData = error.error.error ? error.error.error : error.error;
          this._snackBar.open(`${errorData}`, 'OK', {
            duration: 7000,
            panelClass: ['auth-error-snack-bar']
          });
        });
    }
  }

  loginUsingChanged() {
    clearInterval(this.otpInterval);
    this.otpInterval = '';
    this.remainingTime = '';
    this.otpSent = false;
    if (this.loginForm.get('loginUsing').value == 'otp') {
      this.loginViaOTP();
    }else{
      this.setFocusToEncy();
    }
  }

  decrytUserToken() {
    this.authService
      .decrytToken()
      .pipe(
        catchError((error: any) => {
          let loginError = error.error.error ? error.error.error : error.error;
          this.completed = true;
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(decrytResponse => {
        this.currentuserdata = decrytResponse;
        if (this.authService.getAuthorizationToken()) {
          let user = this.authService.getUser();
          this.gstin = !!user ? user.organization.id : null;
          // user
          if (environment.production) {
            const amplitude = require('amplitude-js');
            amplitude.getInstance().init("c266ada641154aeabacbcee96bf9238d");
            amplitude.logEvent('Login page');
            amplitude.getInstance().setUserId(user.email);
            amplitude.getInstance().setUserProperties({ 'Organization Id': user.organization.id });
            amplitude.getInstance().setUserProperties({ 'Organization Type': user.organization.type.name });
            amplitude.getInstance().setUserProperties({ 'Organization Name': user.organization.name });
            amplitude.getInstance().setUserProperties({ 'User Id': user.userId });
            amplitude.getInstance().setUserProperties({ 'User Name': user.name });
            amplitude.getInstance().setUserProperties({ 'User Email': user.email });
            if(user && user.roleId){
              amplitude.getInstance().setUserProperties({ 'Role Id': user.roleId });
              amplitude.getInstance().setUserProperties({ 'Role Name': user.roleName });
            }
          }
          // putting the search() function here directly here
          this.authService.getAccessModule()
            .pipe(
              catchError((error: any) => {
                return throwError(error);
              }),
              finalize(() => {
              })
            )
            .subscribe(accessModuleResponse => {
              // accessModuleResponse
              localStorage.setItem("defaultLandingModule", accessModuleResponse.landingModule);
              localStorage.setItem("accessGroupDataByLabel", JSON.stringify(accessModuleResponse.groupedUserSiteModules));
              localStorage.setItem("accessGroupData", JSON.stringify(accessModuleResponse.userModuleAccessGroups));
              localStorage.setItem("userModules", JSON.stringify(accessModuleResponse));
              //1 Super;2 Shipper;3 Carrier
              let userType = !!user ? user.organization.type.id : null;
              if (userType == 2) {
                if (this.redirectUrl) {
                  this.completed = true;
                  let url = this.redirectUrl.toString();
                  let pathname = new URL(url).pathname;
                  if (pathname.includes("/manufacturer")) {
                    this.router.navigate([`${pathname}`]);
                  }
                  else {
                    this.goToMfgProfile(accessModuleResponse.landingModule);
                  }

                } else if (accessModuleResponse.landingModule) {
                  this.completed = true;
                  //accessModuleResponse.landingModule
                  this.goToMfgProfile(accessModuleResponse.landingModule);

                }
                else {
                  this.completed = true;
                  this.router.navigate(["/manufacturer/manage-organization"]);
                }
              }
              else if (userType == 3) {
                if (this.redirectUrl) {
                  this.completed = true;
                  let url = this.redirectUrl.toString();
                  let pathname = new URL(url).pathname;

                  if (pathname.includes("/transporter")) {
                    this.router.navigate([`${pathname}`]);
                  }
                  else {
                    this.getTransporterDetails(this.gstin, accessModuleResponse.landingModule);
                  }
                }
                else {
                  this.getTransporterDetails(this.gstin, accessModuleResponse.landingModule);
                }
              }
              else if (userType == 1) {
                if (this.redirectUrl) {
                  this.completed = true;
                  let url = this.redirectUrl.toString();
                  let pathname = new URL(url).pathname;

                  if (pathname.includes("/admin")) {
                    this.router.navigate([`${pathname}`]);
                  }
                  else {
                    this.router.navigate(['/admin/transporters']);
                  }
                }
                else {
                  this.completed = true;
                  this.router.navigate(['/admin/transporters']);
                }
              }
              else {
                this.completed = true;
                this.router.navigate(['/login']);
              }
            });
          return false;
        }
        else {
          this.router.navigate(['/login']);
        }
      });
  }

  dataObject() {
    this.loginSubmitted = true;
    clearInterval(this.otpInterval);
    this.otpInterval = '';
    this.remainingTime = '';
    if(this.loginForm.get('loginUsing').value == 'otp' && this.otp == undefined){
      this._snackBar.open(`Enter OTP`, 'OK', {
        duration: 7000,
        panelClass: ['auth-error-snack-bar']
      });
      return;
    }

    if (this.loginForm.get('loginUsing').value == 'otp' && (this.otp.toString()).length < 4) {
      this._snackBar.open(`OTP should be of 6 Digits!`, 'OK', {
        duration: 7000,
        panelClass: ['auth-error-snack-bar']
      });
      return;
    }

    if (this.loginForm.get('loginUsing').value == 'mfa' && this.loginForm.get('password').invalid) {
      this._snackBar.open(`Invalid user credentials `, 'OK', {
        duration: 7000,
        panelClass: ['auth-error-snack-bar']
      });
      return;
    }

    this.completed = false;
    let username = this.loginForm.value.username.toLowerCase();
    let password = '';
    const loginMode = this.loginForm.get('loginUsing').value;
    switch(loginMode){
      case 'otp':
        password = this.otp;
        break;
      case 'mfa':
        password = this.loginForm.get('password').value;
        break;
      case 'mfa_code_verification':
        password = this.otp;
        break;
    }
    
    if(loginMode == 'mfa_code_verification'){
      this.authService.loginWithMfa(username, password)
      .subscribe((loginRes: any) => {
        this.decrytUserToken();
      }, error => {
        this.completed = true;
        let loginError = error.error.error ? error.error.error : error.error;
        this._snackBar.open(`${loginError}`, 'OK', {
          duration: 7000,
          panelClass: ['auth-error-snack-bar']
        });
      });
    }else{
      const payload = {
        username: username,
        password: password
      }
      this.authService.login(payload)
      .subscribe((loginRes: any) => {
        if(loginMode == 'mfa'){
          this.completed = true;
          this.loginForm.get('loginUsing').patchValue('mfa_code_verification');
          this.loginForm.get('password').patchValue('');
        }else{
          this.decrytUserToken();
        }
      }, error => {
        this.completed = true;
        let loginError = error.error.error ? error.error.error : error.error;
        this._snackBar.open(`${loginError}`, 'OK', {
          duration: 7000,
          panelClass: ['auth-error-snack-bar']
        });
      });
    }
  }


  convertToCaps(event) {
    if (this.signUpForm.get("pan").value) {
      this.signUpForm.get("pan").patchValue(this.signUpForm.get("pan").value.toUpperCase());
    }
  }


  registerUser() {
    this.signUpSubmitted = true;
    if(!this.signUpForm.value.termsAccepted){
      return;
    }
    if (this.signUpForm.valid) {
      this.completed = false;
      let pan = this.signUpForm.value.pan;
      var name = this.signUpForm.value.email.substring(0, this.signUpForm.value.email.lastIndexOf("@"));
      pan = (pan && pan.toUpperCase) ? pan.toUpperCase() : pan;

      this.authService.signup(this.signUpForm.value.email,
        pan,
        name,
        this.signUpForm.value.password,
        this.signUpForm.value.mobile).subscribe((data) => {
          this.signUpSubmitted = true;
          this.completed = true;
          this._snackBar.open(`Email verification link has been sent to ${this.signUpForm.value.email}`, 'OK', {
            duration: 10000,
            panelClass: ['auth-success-snack-bar']
          });
          this.switchTab('login');
        }, error => {
          this.completed = true;
          this.signUpSubmitted = true;
          let loginError = error.error.error ? error.error.error : error.error;
          this._snackBar.open(`${loginError}`, 'OK', {
            duration: 7000,
            panelClass: ['auth-error-snack-bar']
          });
        });

    }else {
      this.completed = true;
    }

  }


  goToMfgProfile(landingModule) {
    this.authService.goToMfgLandingPage(landingModule);
  }


  getTransporterDetails(gstin, landingModule) {
    this.transporterService
      .getOnboardingData(gstin)
      .pipe(
        catchError((error: any) => {
          this.router.navigate(['/transporter/onboarding/' + gstin]);
          // this.router.navigate(['/transporter/bid/event']);
          this.completed = true;
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        if (data === undefined || !data["isApproved"]) {
          this.completed = true;
          this.router.navigate(['/transporter/onboarding/' + gstin]);
        }
        else {
          this.completed = true;
          this.goToTsp(landingModule);
        }
      });
  }

  loginUsingMagicLink(){
    this.authService.loginUsingMagicLink(this.userVerificationToken).subscribe( resp => {
      this.decrytUserToken();
    }, error => {
      this.userVerificationInfo = 'User login failed';
    });
  }

  openTermsAndConditionsPopup(){
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '820px',
      data: null,
      disableClose: true,
      panelClass: 'ff_dialogBoxWindow',
    });

    dialogRef.afterClosed().subscribe(resp => {
      if(this.isUserVerificationMode){
        if(resp == 'CONFIRM'){
          this.verifyNewUserWithToken();
        }else {
          this.userVerificationInfo = 'User verification failed';
        }
      }else{
        if(resp != 'CONFIRM'){
          this.signUpForm.get('termsAccepted').patchValue(false);
        }
      }
    });
  }

  toggleTermsAcceptance(termsAccepted){
    if(termsAccepted){
      this.openTermsAndConditionsPopup();
    }
  }

  verifyNewUserWithToken(){
    this.authService.verifyNewUser(this.userVerificationToken).subscribe( resp => {
      this._snackBar.open(`User has been verified, please login to continue.`, 'OK', {
        duration: 7000,
        panelClass: ['auth-success-snack-bar']
      });
      this.isUserVerificationMode = false;
      this.router.navigate(['/login']);
    }, error => {
      this.userVerificationInfo = 'User verification failed';
    });
  }

  goToTsp(landingModule) {
    this.authService.goToTspLandingModule(landingModule);
  }

  reset() {
    this.authService.logout()
    .subscribe(data => {
      this.router.navigate(['/forgot-password']);
    });
    
  }

  gotoSignUp() {
    this.router.navigate(['/signup']);
  }


  submitLogin(event) {
    if (event.key == 'Enter') {
      if (!this.usernameSubmitted) {
        this.usernameChange();
      }
      else {
        this.dataObject();
      }
    }

  }

  submitSignUp(event) {
    if (event.keyCode == 13) {
      this.registerUser()
    }
  }

}
