<!-- vahan details template  -->
<ng-template #vahanPopupTemplate let-isLoadingData="isLoadingData" let-vahanData="vahanData">
    <div class="vahan-template">
        <ng-container *ngIf="isLoadingData; else vahanDetailsContainer">
            <div class="document-data">
                <p>Loading Vahan Data</p>
            </div>
        </ng-container>
        <ng-template #vahanDetailsContainer>
            <div class="document-data">
                <p>{{vahanData.vehicleNumber}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.vehicleCategory}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.makerModel}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.fuelType}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.normsType }}</p>
            </div>
            <div class="document-data">
                <div class="value-pair">
                    <p class="key">Gross Weight: </p>
                    <p class="value">{{vahanData.grossWeight}} {{vahanData.grossWeight != 'NA' ? 'Kg' : ''}}</p>
                </div>
                <div class="seperator"></div>
                <div class="value-pair">
                    <p class="key">Unladen Weight: </p>
                    <p class="value">{{vahanData.unladenWeight}} {{vahanData.grossWeight != 'NA' ? 'Kg' : ''}}</p>
                </div>
            </div>
            <table class="mff_div_table">
                <thead>
                    <tr>
                        <th class="col-10-width">Document</th>
                        <th class="col-8-width">Valid Upto</th>
                        <th class="col-6-width">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of vahanData.documents">
                        <td>{{doc.documentDescription}}</td>
                        <td>{{doc.validUpto}}</td>
                        <td>
                            <div [ngClass]="['doumentStatus', doc.validityStatus]">
                                {{doc.validityStatus}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </div>
</ng-template>

<!-- Delivery Details template -->
<ng-template #receiversFormPopupTemplate let-receiverData="receiverData">
    <div class="details-wrapper">
        <div class="header">
            <p>Unloading Point Observation</p>
        </div>
        <div class="content">
            <div class="delivery-container">
                <p class="header">Reporting time: </p>
                <p class="value">{{receiverData?.destReportedTime | date: 'dd MMM yyyy | hh:mm a'}}</p>
            </div>
            <div class="material-list">
                <div class="material-row table-header">
                    <p>Product Name</p>
                    <p>Damage</p>
                    <p>Shortage</p>
                </div>
                <ng-container *ngFor="let material of receiverData?.materialDetails">
                    <div class="material-row">
                        <p>{{ material.material }}</p>
                        <p>{{ material.breakageQuantity || 0 }}</p>
                        <p>{{ material.shortageQuantity || 0 }}</p>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="receiverData?.repackagingCharges">
                <div class="delivery-container">
                    <p class="header">Other Charges: </p>
                    <p class="value">{{receiverData?.repackagingCharges | indianCurrency}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="receiverData?.remarks">
                <div class="delivery-container">
                    <p class="header">Remarks: </p>
                    <p class="value">{{receiverData?.remarks}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="receiverData?.unloadingDocuments">
                <div class="delivery-container">
                    <p class="header">Images: </p>
                    <div>
                        <ng-container
                            *ngFor="let docImage of receiverData?.unloadingDocuments; let docImageIndex = index ">
                            <div class="image-value"
                                (click)="downloadUnloadingInfoImage(docImage, true);$event.stopPropagation()">
                                <span>{{(docImageIndex+1)}}. </span>{{docImage?.documentName}}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="receiverData?.reporterDetails">
                <div class="delivery-container">
                    <p class="header">Receiver: </p>
                    <p class="value">{{receiverData?.reporterDetails}}</p>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>