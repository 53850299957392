import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../utils.service';

@Component({
  selector: 'app-combined-filter',
  templateUrl: './combined-filter.component.html',
  styleUrls: ['./combined-filter.component.scss']
})
export class CombinedFilterComponent implements OnInit {

  filterController:any = [];

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() singleSelectFilters:any;
  @Input() multiSelectFilters:any;
  @Input() dateFilters:any;
  showDateFilter:boolean = false;
  maxDate:any = new Date();

  constructor(
    private utilSerivce: UtilService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    for(let filter of this.multiSelectFilters){
      let viewController = {
        "name": this.getFilterName(filter['heading'], filter['dataList']),
        "isInFocus": false
      }
      this.filterController.push(viewController);
    }
    if(this.dateFilters && this.dateFilters['fromDate']){
      this.showDateFilter = true;
    }
  }

  applyFilter(){
    let filterValues = {
      "action": "APPLY_FILTER",
      "singleDataList": this.singleSelectFilters,
      "multiDataList": this.multiSelectFilters,
    };
    if(this.showDateFilter){
      filterValues["dateFilterList"] = {
        fromDate: this.utilSerivce.formatDateyyyymmdd(this.dateFilters['fromDate']),
        toDate: this.utilSerivce.formatDateyyyymmdd(this.dateFilters['toDate'])
      }  
    }
    this.action.emit(filterValues);
  }

  cancelFilter(){
    let filterValues = {
      "action": "CANCEL_FILTER",
    };
    this.action.emit(filterValues);
  }

  setFocusStateTrue(index){
    if(this.filterController[index]['isInFocus'] == true){
      this.filterController[index]['isInFocus'] = false;
    }else{
      this.filterController.forEach(ctrl => ctrl['isInFocus'] = false);
      this.filterController[index]['isInFocus'] = true;
    }
  }

  filterAction(event, index) {
    if (event.applyFilter) {
        let existingList = this.multiSelectFilters[index]['dataList'];
        let newFilters = event.filters;

        // Preserve existing list reference and update only isSelected values
        existingList.forEach(item => {
            let matchedItem = newFilters.find(newItem => newItem.key === item.key);
            if (matchedItem) {
                item.isSelected = matchedItem.isSelected;
            }
        });

        let filter = this.multiSelectFilters[index];
        this.filterController[index]['name'] = this.getFilterName(filter['heading'], filter['dataList']);
    }
}

getFilterName(heading, dataList) {
    let selectedCount = dataList.filter(item => item.isSelected).length;

    if (selectedCount === 0 || selectedCount === dataList.length) {
        return 'All ' + heading;
    } else if (selectedCount === 1) {
        return this.getSelectedVal(dataList);
    } else {
        return selectedCount + ' ' + heading;
    }
}

getSelectedVal(dataList) {
    let selectedItem = dataList.find(res => res.isSelected);
    return selectedItem ? selectedItem.key : '';
}


}
