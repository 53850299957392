import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../snackbarService/snackbar.service';
import { AlertModelComponent } from './alert-model/alert-model.component';
import * as XLSX from 'xlsx';
import { PrivateTspListComponent } from '../admin/shared/private-tsp-list/private-tsp-list.component';
import { PrivateTspGroupComponent } from '../admin/shared/private-tsp-group/group-tsp.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { FfConfirmationComponent } from './ff-confirmation/ff-confirmation.component';
type AOA = any[][];

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    doremonUrl: any = environment.s3baseUrl;
    monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    private vehicleNoValidatorRegexString: string = '^(?:[A-Za-z]{2}[0-9]{1,2}[A-Za-z]{0,3}[0-9]{4})$|(?:[0-9]{2}(BH|bh)[0-9]{4}[A-Za-z]{1,2})$';

    constructor(
        public dialog: MatDialog,
        private http: HttpClient,
        private snackbar: SnackbarService,
    ) { }

    getVehicleNoValidatorRegexString(){
        return this.vehicleNoValidatorRegexString;
    }

    formatDateyyyymmdd(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let convertedDate = year + '-' + ("0" + month).slice(-2)
            + '-' + ("0" + day).slice(-2);

        return convertedDate;
    }

    formatDateTime(date, time) {
        let timeArray = time.split(/[ :]+/);
        let zone = timeArray[2];
        let minute = timeArray[1];
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        // convert to 24 hours
        let hour24 = timeArray[0];
        if (zone === 'PM' || zone === 'pm' || zone === 'Pm') {
            if (hour24 !== "12") {
                hour24 = 12 + parseInt(hour24);
            }
        } else if (zone === 'AM' || zone === 'am' || zone === 'Am') {
            if (hour24 === "12") {
                hour24 = 0;
            }
        }
        // "startTime": "2020-12-28T09:30:00+05:30"
        let convertedDate = year + '-' + ("0" + month).slice(-2)
            + '-' + ("0" + day).slice(-2) + 'T'
            + ("0" + hour24).slice(-2) + ':' + ("0" + minute).slice(-2)
            + ":00+05:30";

        return convertedDate;
    }

    formatTo12Hour(timestamp): string {
        // Create a Date object from the UTC timestamp
        const date = new Date(timestamp);
    
        // Extract hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes();
        let period = 'am';
      
        // Convert hours from 24-hour format to 12-hour format
        if (hours >= 12) {
          period = 'pm';
          if (hours > 12) {
            hours -= 12;
          }
        } else if (hours === 0) {
          hours = 12;
        }
      
        // Format minutes to always be two digits
        const minutesFormatted = minutes.toString().padStart(2, '0');
      
        // Return formatted time
        return `${hours}:${minutesFormatted} ${period}`;
      }

    format24HourDateTime(date, time) {
        let timeArray = time.split(/[ :]+/);
        let minute = timeArray[1];
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        // convert to 24 hours
        let hour24 = timeArray[0];
        
        // "startTime": "2020-12-28T09:30:00+05:30"
        let convertedDate = year + '-' + ("0" + month).slice(-2)
            + '-' + ("0" + day).slice(-2) + 'T'
            + ("0" + hour24).slice(-2) + ':' + ("0" + minute).slice(-2)
            + ":00+05:30";

        return convertedDate;
    }
    
    formatyyyymmddThhmm530(timeStamp) {
        const newDate = new Date(timeStamp);
        const day = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();
        // convert to 24 hours
        const hours = newDate.getHours();
        const minutes = newDate.getMinutes();
        
        // "startTime": "2020-12-28T09:30:00+05:30"
        let convertedDate = year + '-' + ("0" + month).slice(-2)
            + '-' + ("0" + day).slice(-2) + 'T'
            + ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2)
            + ":00+05:30";

        return convertedDate;
    }

    formatDateddmmyyyy(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let monthNumber = newDate.getMonth();
        let month = this.monthNames[monthNumber];
        let year = newDate.getFullYear();
        let convertedDate = ("0" + day).slice(-2) + '-' + month + '-' + year;
        return convertedDate;
    }

    formatDateddmmyyyyHHMM(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let monthNumber = newDate.getMonth();
        let month = this.monthNames[monthNumber];
        let year = newDate.getFullYear();
        let hour = newDate.getHours();
        let minutes = newDate.getMinutes();
        let convertedDate = ("0" + day).slice(-2) + ' ' + month + ' ' + year +  ' - ' +hour+':'+minutes;
        return convertedDate;
    }

    formatDateddmmyyyyHHMMInUTC(date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(newDate.getUTCDate()).padStart(2, '0');
        const hours = String(newDate.getUTCHours()).padStart(2, '0');
        const minutes = String(newDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(newDate.getUTCSeconds()).padStart(2, '0');
        let convertedDate =`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
        return convertedDate;
    }

    formatDateddmmyyyyHHMMAMPM(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let monthNumber = newDate.getMonth();
        let month = this.monthNames[monthNumber];
        let year = newDate.getFullYear();
        var hours = newDate.getHours();
        var minutes:any = newDate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let convertedDate = ("0" + day).slice(-2) + ' ' + month + ' ' + year +  ' - ' + hours + ':' + minutes + ' ' + ampm;
        return convertedDate;
    }

    formatDateHHMMAMPM(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let monthNumber = newDate.getMonth();
        let month = this.monthNames[monthNumber];
        let year = newDate.getFullYear();
        var hours = newDate.getHours();
        var minutes:any = newDate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let convertedDate = hours + ':' + minutes + ' ' + ampm;
        return convertedDate;
    }

    formatDateddmmyyyyHHMMSS(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var hours = newDate.getHours();
        var minutes:any = newDate.getMinutes();
        var seconds:any = newDate.getSeconds();
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let convertedDate = year + '-' + ("0" + month).slice(-2) + '-' + ("0" + day).slice(-2)  +  ' ' + ("0" + hours).slice(-2) + ':' + minutes + ':' + ("0" + seconds).slice(-2);
        return convertedDate;
    }
    
    formatDateyyyymmddTHHMMSS(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var hours = newDate.getHours();
        var minutes:any = newDate.getMinutes();
        var seconds:any = newDate.getSeconds();
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let convertedDate = year + '-' + ("0" + month).slice(-2) + '-' + ("0" + day).slice(-2)  +  'T' + ("0" + hours).slice(-2) + ':' + minutes + ':' + ("0" + seconds).slice(-2);
        return convertedDate;
    }

    getConfirmation(message,heading?) {
        return new Promise(resolve => {
            let data = {
                heading: heading?heading:'Confirmation',
                message: message,
                type: 'confirm'
            }
            const dialogRef = this.dialog.open(AlertModelComponent, {
                width: '420px',
                data: data,
                disableClose: true,
                panelClass: 'addAlertDailog',
                autoFocus: false,
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result.data === 'YES') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    openConfirmationModal(heading, message, subButtonText?, secondaryButtonText?){
        return new Promise(resolve => {
            let header = heading;
            let detailText = message;
            if(!subButtonText){
                subButtonText = 'Confirm';
            }
            if(!secondaryButtonText){
                secondaryButtonText = 'Cancel';
            }
            const dialogRef = this.dialog.open(FfConfirmationComponent, {
            width: '420px',
            data: { header: header, submitButtonText: subButtonText, secondaryButtonText: secondaryButtonText, detailText: detailText },
            disableClose: true,
            panelClass: 'ff_dialogBoxWindow',
            });
    
            dialogRef.afterClosed().subscribe(resp => {
                if (resp == 'CONFIRM') {
                    resolve(true);
                }else{
                    resolve(false);
                }
            });
        });
    }

    showInfoDialog(infoMessage) {
        return new Promise(resolve => {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
                width: '460px',
                data: infoMessage,
                disableClose: true,
                panelClass: 'ff_dialogBoxWindow',
                autoFocus: false
            });
            dialogRef.afterClosed().subscribe(result => resolve(true));
        });
    }

    formatExcelData(event) {
        return new Promise(resolve => {
            let excelData: AOA = [[1, 2], [3, 4]];
            let rowsData: any;
            let excelHeaders: any;
            let jsonDataArray: any = [];
            const target: DataTransfer = <DataTransfer>(event.target);
            if (target.files.length !== 1) throw new Error('Cannot use multiple files');
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                /* save data */
                excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
                rowsData = excelData;
                excelHeaders = rowsData[0];
                for (let [index, data] of rowsData.entries()) {
                    if (index > 0) {
                        let newObject: any = {};
                        for (let [i, keyName] of excelHeaders.entries()) {
                            newObject[keyName] = data[i];
                        }
                        jsonDataArray.push(newObject);
                    }
                }
                resolve({ headers: excelHeaders, dataArray: jsonDataArray });
            };
            reader.readAsBinaryString(target.files[0]);
        });
    }

    importExcel(evt: any) {
        return new Promise(resolve => {
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                let data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
                let rawData = data;
                let excelHeaders = rawData[0];
                let jsonDataArray = [];
                for (let [index, data] of rawData.entries()) {
                    if (index > 0) {
                        let newObject: any = {};
                        for (let [i, keyName] of excelHeaders.entries()) {
                            newObject[keyName] = data[i];
                        }
                        jsonDataArray.push(newObject);
                    }
                }
                let excelData = {
                    headers: excelHeaders,
                    dataArray: jsonDataArray,
                }
                resolve(excelData);
            };
            reader.readAsBinaryString(evt[0]);
        })
    }

    // Download Documnets with API call
    downloadDoc(name, id, key, view: boolean, type?) {
        const fileExtension = name.split('.').pop()?.toLowerCase();
        if(fileExtension  != 'pdf'){
            view = false;
        }
        let todayDate = new Date().getTime();
        this.snackbar.showInfoSnackBar('Downloading document', 'X');
        this.downloadDocApiCall(id, key, view)
            .pipe(
                catchError((error: any) => {
                    this.snackbar.showErrorSnackBar('Something went wrong', 'X');
                    return throwError(error);
                }),
            )
            .subscribe((data: any) => {
                if (!data) {
                    this.snackbar.showInfoSnackBar('Document is being generated, please wait.', 'X');
                    return;
                }
                let fileType;
                switch (type) {
                    case 'xlsx':
                        fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64"
                        break;
                    case 'pdf':
                        fileType = "application/pdf"
                        break;
                    case 'png':
                        fileType = "image/png"
                        break;
                    case 'jpeg':
                        fileType = "image/jpeg"
                        break;
                    case 'jpg':
                        fileType = "image/jpg"
                        break;
                    default:
                        fileType = "application/pdf"
                        break;
                }
                // let fileType = type ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," : 'application/pdf'; 
                let file = new Blob([data], { type: fileType })
                
                let fileURL = URL.createObjectURL(file);
                // if you want to open PDF in new tab
                if (view && type != 'xlsx') {
                    window.open(fileURL);
                }
                var a = document.createElement('a');
                a.href = fileURL;
                // a.target = view ? '_blank' : '';
                a.download = name;
                document.body.appendChild(a);
                a.click();
                this.snackbar.showSuccessSnackBar('Document downloaded successfully', 'X')
            });
    }

    generateDocument(body) {
        if (environment.doremonDocKey && environment.doremonDocKey != '') {
            let options = new HttpHeaders().set('Authorization', environment.doremonDocKey);
            return this.http
                .post<any>(`${this.doremonUrl}doraemon/api/v1/generate/excel/document`, body, {
                    headers: options
                })
                .pipe(
                    map(data => {
                        let dataToCache = data;
                        return dataToCache;
                    }),

                ) as Observable<any>;
        }
    }

    // Document API call 
    private downloadDocApiCall(id, key, view: boolean): Observable<Blob> {
        const httpOptions = {
            responseType: 'blob' as 'json',
            headers: new HttpHeaders({
                'Authorization': environment.doremonDocKey,
            })
        };
        return this.http
            .get<Blob>(this.doremonUrl + 'doraemon/api/v1/document/' + id + '/' + key + `?view=${view}`,
                httpOptions,
            ) as Observable<Blob>;
    }

    // difference between two timestamps based on dates (in Days - only consider dates)
    diffInDays_DateBased(inDateStamp: number, outDateStamp: number): number {
        let days = 0;
        let inDate = new Date(moment(inDateStamp).format('YYYY-MM-DD'));
        let outDate = new Date(moment(outDateStamp).format('YYYY-MM-DD'));
        let diffTime = outDate.getTime() - inDate.getTime();
        if ((inDate !== outDate) && diffTime > 0) {
            days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
        return days;
    }

    getTimeToEnd(endTime, type: 'hours' | 'minutes' | 'seconds') {
        if (endTime) {
            let date = new Date().getTime();
            let timeLeft = endTime - date;
            if (timeLeft <= 0) {
                return 'TIME_OVER';
            }
            var hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            if (type == 'hours') {
                return ("0" + hours).slice(-2);
            }
            else if (type == 'minutes') {
                return ("0" + minutes).slice(-2);
            }
            else if (type == 'seconds') {
                return ("0" + seconds).slice(-2);
            }
        }
    }

    getTimeToStart(startTime, type: 'hours' | 'minutes' | 'seconds') {
        if (startTime) {
            let date = new Date().getTime();
            let timeLeft = startTime - date;
            if (timeLeft <= 0) {
                return 'TIME_OVER';
            }
            var hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            if (type == 'hours') {
                return ("0" + hours).slice(-2);
            }
            else if (type == 'minutes') {
                return ("0" + minutes).slice(-2);
            }
            else if (type == 'seconds') {
                return ("0" + seconds).slice(-2);
            }
        }
    }

    getRemainingTime(timeStamp: number) {
        let currentTime = new Date().getTime();
        let timeLeft = timeStamp - currentTime;
        if (timeLeft <= 0) {
            return '00:00:00';
        }
        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        let timeRemain = ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
        return timeRemain;
    }

    getRemainingTimeInDays(timeStamp: number) {
        let currentTime = new Date().getTime();
        let timeLeft = timeStamp - currentTime;
        if (timeLeft <= 0) {
            return '00:00:00';
        }
        let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        let timeRemain = ("0" + days).slice(-2) + 'D:' + ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);;
        if(days == 0){
            timeRemain = ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
        }
        return timeRemain;
    }
    
    getElapsedTime(timeStamp: number) {
        let currentTime = new Date().getTime();
        let timeLeft = Math.abs(timeStamp - currentTime);
        if (timeLeft <= 0) {
            return '00:00:00';
        }
        let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        let timeElapsed = days + ':' + hours + ':' + minutes + ':' + seconds;
        return timeElapsed;
    }

    createPrivateTspGroup(priavteTspGroup) {
        return new Promise(resolve => {
            const dialogRef = this.dialog.open(PrivateTspGroupComponent, {
                width: '800px',
                data: { tspList: priavteTspGroup },
                disableClose: true,
                panelClass: 'dialogBoxWindow',
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    resolve(result.slice());
                } else {
                    resolve(false);
                }
            });
        });
    }

    openTspsInfoDialog(allowedTransportersInfo) {
        const dialogRef = this.dialog.open(PrivateTspListComponent, {
            width: '420px',
            data: { allTsp: allowedTransportersInfo },
            disableClose: true,
            panelClass: 'dialogBoxWindow',
        });
        dialogRef.afterClosed().subscribe();
    }

    indianCurrencyFormat(value: any) {
        let toChange = value.toString();
        let changed = toChange.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
        changed = '₹ ' + changed;
        return changed;
    }

    indianNFormatter(value: any) {
        let toChange = value.toString();
        let changed = toChange.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
        return changed;
    }

    nFormatter(num, digits) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: " K" },
          { value: 1e5, symbol: " L" },
          { value: 1e7, symbol: " Cr" },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    getCamelCaseToSentanceCase(string) {
        const result = string.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    getStringToSentanceCase(string){
        var splitStr = string.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
    }

    areDatesEqual(timestamp1: Date, timestamp2: Date): boolean {
        const date1 = new Date(timestamp1);
        const date2 = new Date(timestamp2);
      
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
    }

    colorPallets:any = [
        // mixed color
        '#37aeb3',
        '#ba55d3',
        '#EC2049',
        '#087abb',
        '#4B0082', 
        '#d06a66',
        '#7658ab',
        '#F26B38',
        '#A7226E',
        '#1E90FF',
        '#00CED1',
        '#FF4500',
        '#8B0000',
        '#8FBC8F',
        '#800080',
        '#BDB76B',
        '#C71585',
        '#778899',
        '#FF9900',
        '#FFCC00',
        '#FFA07A', 
        '#32CD32',
        '#DA70D6',
        '#5F9EA0',
        '#FA8072',
        '#4682B4',
        '#00CED1',
        '#9370DB',
        '#CD5C5C',
        '#A0522D',
        '#00008B',
        '#DC143C',
        '#2E8B57',
        '#FF0000',
        '#DAA520',
        '#00BFFF',
        '#B22222',
        '#003333',
        '#228B22',
        '#556B2F',
        // orange color
        '#FFA500',
        '#FF7F50',
        // yellow color' 
        '#FFD700',
        '#FFFF00',        
        // green color        
        '#006400',
        '#9ACD32',
        '#6B8E23',
        '#66CDAA',
        '#20B2AA',
        '#008B8B',
        // purple color
        '#EE82EE',
        '#FF00FF',
        '#9932CC',
        '#8A2BE2',
        '#7B68EE',
        '#483D8B',
        '#663399',
        // blue color'
        '#B0C4DE',
        '#87CEFA',
        '#87CEEB',
        '#6495ED',
        '#00BFFF',
        '#0000CD',
        // brown color
        '#FFEBCD',
        '#FFDEAD',
        '#F5DEB3',
        '#DEB887',
        '#BC8F8F',
        '#F4A460',
        '#B8860B',
        '#CD853F',
        '#D2691E',
        '#808000',
        '#8B4513',       
        '#A52A2A',
        '#800000',
        // gray color
        '#A9A9A9',
        '#696969',
        '#808080',
        '#2F4F4F',
        //pink color
        '#FF69B4',
        '#FF1493',
        '#DB7093',
        //70
        '#003300',	
        '#003366',
        '#330000',
        '#333300',
        '#660000',
        '#660000',
        '#993300',
    ];

    getIndentStatusTextAndClass(indent): { status: String, statusClass: String } {
        let status = '-';
        let statusClass: 'BLUE_INFO' | 'ORANGE_WARN' | 'RED_ALERT' | 'GREEN_GO' | 'BROWN_CLOSE' = 'BLUE_INFO';
        switch (indent.indentStatus || indent.status) {
            case 'AWAITED':
                status = 'Response Pending';
                statusClass = 'BLUE_INFO';
                if (indent.responseTimeSlaMissed || indent.isCritical) {
                    statusClass = 'RED_ALERT';
                }
                break;
            case 'PARTIALLY_ACCEPTED':
                status = 'Partially Accepted';
                statusClass = 'ORANGE_WARN';
                break;
            case 'ACCEPTED':
                status = 'Accepted';
                statusClass = 'GREEN_GO';
                if (indent.vehicleStatus) {
                    switch (indent.vehicleStatus) {
                        case 'EXPECTED':
                            status = 'Expected';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'REPORTED':
                            status = 'Reported';
                            statusClass = 'GREEN_GO';
                            if(!indent.dcPassed && indent.hasDcFailed){
                                statusClass = 'RED_ALERT';
                            }
                            break;
                        case 'GATE_IN':
                            status = 'Gate In';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'QC_FAILED':
                            status = 'QC Failed';
                            statusClass = 'RED_ALERT';
                            break;
                        case 'LOADING':
                            status = 'In Loading';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'LOADED':
                            status = 'Loaded';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'IN_TRANSIT':
                            status = 'In Transit';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'TRIP_COMPLETE':
                            status = 'Trip Completed';
                            statusClass = 'BROWN_CLOSE';
                            break;
                        case 'UNSERVICEABLE':
                            status = 'Unserviceable';
                            statusClass = 'RED_ALERT';
                            break;
                        case 'DEST_REACHED':
                            status = 'Reached';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'UNLOADED':
                            status = 'Unloaded';
                            statusClass = 'GREEN_GO';
                            break;
                        case 'NO_SHOW':
                            status = 'No Show';
                            statusClass = 'RED_ALERT';
                            break;
                        case 'TRIP_INCOMPLETE':
                            status = 'Trip Incomplete';
                            statusClass = 'RED_ALERT';
                            break;
                        case 'REALLOCATED':
                            status = 'Reallocated';
                            statusClass = 'BLUE_INFO';
                            break;
                        case 'RETURNED':
                            statusClass = 'RED_ALERT';
                            break;
                    }
                }
                break;
            case 'DELIVERED':
                status = 'Delivered';
                statusClass = 'GREEN_GO';
                if(indent.vehicleStatus == 'TRIP_COMPLETE'){
                    status = 'Trip Completed';
                    statusClass = 'BROWN_CLOSE';
                }
                break;
            case 'REJECTED':
                status = 'Rejected';
                statusClass = 'RED_ALERT';
                break;
            case 'NO_SHOW':
                status = 'No Show';
                statusClass = 'RED_ALERT';
                break;
            case 'REVOKED':
                status = 'Revoked';
                statusClass = 'RED_ALERT';
                break;
            case 'CLOSED':
                status = 'Closed';
                statusClass = 'BROWN_CLOSE';
                break;
        }
        return { status: status, statusClass: statusClass };
    }

    getIndentVehStatus(veh) {
        let status;
        let statusClass = veh.status;
        switch (statusClass) {
            case 'EXPECTED':
                status = 'Expected';
                break;
            case 'REPORTED':
                status = 'Reported';
                break;
            case 'REJECTED':
                status = 'Rejected';
                break;
            case 'GATE_IN':
                status = 'Gate In';
                break;
            case 'QC_FAILED':
                status = 'QC Failed';
                break;
            case 'LOADING':
                status = 'In Loading';
                break;
            case 'LOADED':
                status = 'Loaded';
                break;
            case 'IN_TRANSIT':
                status = 'In Transit';
                break;
            case 'TRIP_COMPLETE':
                status = 'Trip Completed';
                break;
            case 'UNSERVICEABLE':
                status = 'Unserviceable';
                break;
            case 'NO_SHOW':
                status = 'No Show';
                break;
            case 'TRIP_INCOMPLETE':
                status = 'Trip Incomplete';
                break;
            case 'REALLOCATED':
                status = 'Reallocated';
                break;
            case 'DEST_REACHED':
                status = 'Reached';
                break;
            case 'UNLOADED':
                status = 'Unloaded';
                break;
            case 'RETURNED':
                status = 'Returned';
                break;
        }
        return status;
    }

    getIndentFilterStatus(): any[] {
        return [
            {
                key: 'Response Pending',
                value: 'AWAITED',
                isSelected: true
            },
            {
                key: 'Accepted',
                value: 'ACCEPTED',
                isSelected: true
            },
            {
                key: 'Expected',
                value: 'EXPECTED',
                isSelected: true
            },
            {
                key: 'Reported',
                value: 'REPORTED',
                isSelected: true
            },
            {
                key: 'Gate In',
                value: 'GATE_IN',
                isSelected: true
            },
            {
                key: 'In Loading',
                value: 'LOADING',
                isSelected: true
            },
            {
                key: 'Loaded',
                value: 'LOADED',
                isSelected: true
            },
            {
                key: 'In Transit',
                value: 'IN_TRANSIT',
                isSelected: true
            },
            {
                key: 'Reached',
                value: 'DEST_REACHED',
                isSelected: true
            },
            {
                key: 'Unloaded',
                value: 'UNLOADED',
                isSelected: true
            },
            {
                key: 'Delivered',
                value: 'DELIVERED',
                isSelected: true
            },
            {
                key: 'Trip Completed',
                value: 'TRIP_COMPLETE',
                isSelected: true
            },
            {
                key: 'Rejected',
                value: 'REJECTED',
                isSelected: true
            },
            {
                key: 'Revoked',
                value: 'REVOKED',
                isSelected: true
            },
            {
                key: 'No Show',
                value: 'NO_SHOW',
                isSelected: true
            },
            {
                key: 'Trip Incomplete',
                value: 'TRIP_INCOMPLETE',
                isSelected: true
            },
            {
                key: 'Expired',
                value: 'EXPIRED',
                isSelected: true
            }
        ];
    }

    getIndentStatus(): any[] {
        return [
            {
                key: 'Response Pending',
                value: 'RESPONSE_AWAITED',
                isSelected: true
            },
            {
                key: 'Accepted',
                value: 'ACCEPTED',
                isSelected: true
            },
            {
                key: 'Active',
                value: 'ACTIVE',
                isSelected: true
            },
            {
                key: 'Delivered',
                value: 'DELIVERED',
                isSelected: true
            },
            {
                key: 'Closed',
                value: 'INDENT_CLOSED_INVOICED',
                isSelected: true
            },
            {
                key: 'Rejected',
                value: 'REJECTED',
                isSelected: true
            },
            {
                key: 'Revoked',
                value: 'REVOKED',
                isSelected: true
            },
            {
                key: 'Dispatch Canceled',
                value: 'DISPATCH_CANCELLED',
                isSelected: true
            },
            {
                key: 'No Show',
                value: 'NO_SHOW',
                isSelected: true
            },
            {
                key: 'Expired',
                value: 'EXPIRED',
                isSelected: true
            }
        ];
    }

    getIndentStatusData(indent): { status: String, statusClass: String } {
        let status = '-';
        let statusClass: 'BLUE_INFO' | 'ORANGE_WARN' | 'RED_ALERT' | 'GREEN_GO' | 'BROWN_CLOSE' = 'BLUE_INFO';
        switch (indent.indentStatus || indent.status) {
            case 'RESPONSE_AWAITED':
                status = 'Response Pending';
                statusClass = 'BLUE_INFO';
                if (indent.responseTimeSlaMissed || indent.isCritical) {
                    statusClass = 'RED_ALERT';
                }
                break;
           
            case 'ACCEPTED':
                status = 'Accepted';
                statusClass = 'GREEN_GO';
                break;
            case 'ACTIVE':
                status = 'Active';
                statusClass = 'GREEN_GO';
                break;
            case 'DELIVERED':
                status = 'Delivered';
                statusClass = 'GREEN_GO';
                break;
            case 'REJECTED':
                status = 'Rejected';
                statusClass = 'RED_ALERT';
                break;
            case 'INDENT_CLOSED_INVOICED':
                status = 'Closed';
                statusClass = 'BROWN_CLOSE';
                break;
            case 'REVOKED':
                status = 'Revoked';
                statusClass = 'BROWN_CLOSE';
                break;
            case 'DISPATCH_CANCELLED':
                status = 'Dispatch Canceled';
                statusClass = 'RED_ALERT';
                break;
            case 'NO_SHOW':
                status = 'No Show';
                statusClass = 'RED_ALERT';
                break;
            case 'EXPIRED':
                status = 'Expired';
                statusClass = 'RED_ALERT';
                break;
        }

        return { status: status, statusClass: statusClass };
    }

    generateUUID() {
        const hexDigits = '0123456789abcdef';
        let uuid = '';

        for (let i = 0; i < 36; i++) {
            if (i === 8 || i === 13 || i === 18 || i === 23) {
                uuid += '-';
            } else if (i === 14) {
                uuid += '4';
            } else if (i === 19) {
                uuid += hexDigits[(Math.random() * 4) | 8]; // 8, 9, A, or B
            } else {
                uuid += hexDigits[Math.floor(Math.random() * 16)];
            }
        }

        return uuid;
    }

    getStateGstCodes() {
        const indianStatesCode = [
            { state: 'Andaman and Nicobar Islands', stateGstCode: '35' },
            { state: 'Andhra Pradesh', stateGstCode: '37' },
            { state: 'Arunachal Pradesh', stateGstCode: '12' },
            { state: 'Assam', stateGstCode: '18' },
            { state: 'Bihar', stateGstCode: '10' },
            { state: 'Chandigarh', stateGstCode: '04' },
            { state: 'Chhattisgarh', stateGstCode: '22' },
            { state: 'Dadra and Nagar Haveli and Daman and Diu', stateGstCode: '26' },
            { state: 'Delhi', stateGstCode: '07' },
            { state: 'Goa', stateGstCode: '30' },
            { state: 'Gujarat', stateGstCode: '24' },
            { state: 'Haryana', stateGstCode: '06' },
            { state: 'Himachal Pradesh', stateGstCode: '02' },
            { state: 'Jammu and Kashmir', stateGstCode: '01' },
            { state: 'Jharkhand', stateGstCode: '20' },
            { state: 'Karnataka', stateGstCode: '29' },
            { state: 'Kerala', stateGstCode: '32' },
            { state: 'Ladakh', stateGstCode: '38' },
            { state: 'Lakshadweep', stateGstCode: '31' },
            { state: 'Madhya Pradesh', stateGstCode: '23' },
            { state: 'Maharashtra', stateGstCode: '27' },
            { state: 'Manipur', stateGstCode: '14' },
            { state: 'Meghalaya', stateGstCode: '17' },
            { state: 'Mizoram', stateGstCode: '15' },
            { state: 'Nagaland', stateGstCode: '13' },
            { state: 'Odisha', stateGstCode: '21' },
            { state: 'Puducherry', stateGstCode: '34' },
            { state: 'Punjab', stateGstCode: '03' },
            { state: 'Rajasthan', stateGstCode: '08' },
            { state: 'Sikkim', stateGstCode: '11' },
            { state: 'Tamil Nadu', stateGstCode: '33' },
            { state: 'Telangana', stateGstCode: '36' },
            { state: 'Tripura', stateGstCode: '16' },
            { state: 'Uttar Pradesh', stateGstCode: '09' },
            { state: 'Uttarakhand', stateGstCode: '05' },
            { state: 'West Bengal', stateGstCode: '19' }
        ];
        return indianStatesCode;
    }

    convertToCityCodeExcludingDot(inputString){
        // ex: J.P.Nagar
        let convertedString  = inputString.toUpperCase().replace(/[^A-Z.]+/g, '').replace(/\./g, '');
        return convertedString; // JPNAGAR
    }

    isValidGSTIN(gstin): boolean {
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return gstinRegex.test(gstin);
    }

}
