import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TransporterService } from 'src/app/transporter/transporter.service';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  otp: any;
  formSubmitted: boolean = false;
  showRepassword: boolean = false;
  public show: boolean = false;
  public email: string;
  otpInterval: any;
  remainingTime: any;
  otpSent: boolean = false;
  config: any = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };



  public submitted: boolean = false;
  public failed: boolean = false;
  public errorMessage: any;


  public passwordChanged: any = false;

  public updatePasswordForm = this.fb.group({
    password: ['', Validators.required],
    repassword: ['', Validators.required],
    otp: ['']
  });

  constructor(public fb: UntypedFormBuilder, private AuthenticateService: AuthService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private transporterService: TransporterService, private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      let email = params.get("email");
      this.email = email;
      this.otpSent = true;
    });
  }

  goToReset() {
    this.router.navigate(['/forgot-password']);
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  updatePassword() {
    if (this.otp && (this.otp.toString()).length < 4) {
      this._snackBar.open(`OTP should be of 4 Digits!`, 'OK', {
        duration: 5000,
        panelClass: ['auth-error-snack-bar']
      });
      return;
    }
    else if (this.otp && this.updatePasswordForm.get('password').value && this.updatePasswordForm.get('repassword').value && (this.updatePasswordForm.get('password').value == this.updatePasswordForm.get('repassword').value)) {
      this.formSubmitted = true;
      this.AuthenticateService.updatePassword(this.email,
        this.updatePasswordForm.value.password,
        this.otp)
        .pipe(
          catchError((error: any) => {
            let errorData = error.error.error ? error.error.error : error.error;
            this._snackBar.open(`${errorData}`, 'OK', {
              duration: 5000,
              panelClass: ['auth-error-snack-bar']
            });
            return throwError(error);
          }),
          finalize(() => {
          })
        )
        .subscribe((data) => {
          this.passwordChanged = true;
          this._snackBar.open(`Password has been updated successfully`, 'OK', {
            duration: 5000,
            panelClass: ['auth-success-snack-bar']
          });
          this.router.navigate(['/login']);
          //this.login();
        });
    }

    else {
      this.formSubmitted = true;
    }
  }

  goTologin() {
    this.router.navigate(['/login']);
  }

  sendOtp() {
    if (this.email) {
      this.remainingTime = '';
      this.AuthenticateService.sendLoginOTP(this.email)
        .subscribe((data) => {
          this._snackBar.open(`OTP has been sent successfully to ${this.email}`, 'OK', {
            duration: 5000,
            panelClass: ['auth-success-snack-bar']
          });
          this.otpSent = true;
          let startTimer = 30;
          this.remainingTime = "00:" + ("" + startTimer).padStart(2, '0');
          this.otpInterval = setInterval(() => {
            this.remainingTime = "00:" + ("" + startTimer).padStart(2, '0');
            startTimer--;
            if (startTimer <= 0) {
              clearInterval(this.otpInterval);
              this.otpInterval = null;
            }
          }, 1000);
        }, error => {


        });
    }
  }


  submitUpdatePassword(event) {
    if (event.keyCode == 13) {
        this.updatePassword();
      }
  }


}
