<!-- vahan details template  -->
<ng-template #vahanPopupTemplate let-isLoadingData="isLoadingData" let-vahanData="vahanData">
    <div class="vahan-template">
        <ng-container *ngIf="isLoadingData; else vahanDetailsContainer">
            <div class="document-data">
                <p>Loading Vahan Data</p>
            </div>
        </ng-container>
        <ng-template #vahanDetailsContainer>
            <div class="document-data">
                <p>{{vahanData.vehicleNumber}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.vehicleCategory}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.makerModel}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.fuelType}}</p>
                <div class="seperator"></div>
                <p>{{vahanData.normsType }}</p>
            </div>
            <div class="document-data">
                <div class="value-pair">
                    <p class="key">Gross Weight: </p>
                    <p class="value">{{vahanData.grossWeight}} {{vahanData.grossWeight != 'NA' ? 'Kg' : ''}}</p>
                </div>
                <div class="seperator"></div>
                <div class="value-pair">
                    <p class="key">Unladen Weight: </p>
                    <p class="value">{{vahanData.unladenWeight}} {{vahanData.grossWeight != 'NA' ? 'Kg' : ''}}</p>
                </div>
            </div>
            <table class="mff_div_table">
                <thead>
                    <tr>
                        <th class="col-10-width">Document</th>
                        <th class="col-8-width">Valid Upto</th>
                        <th class="col-6-width">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of vahanData.documents">
                        <td>{{doc.documentDescription}}</td>
                        <td>{{doc.validUpto}}</td>
                        <td>
                            <div [ngClass]="['doumentStatus', doc.validityStatus]">
                                {{doc.validityStatus}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table> 
        </ng-template>
    </div>
</ng-template>
<!-- // vahan details template -->
