import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { SnackbarService } from 'src/app/snackbarService/snackbar.service';

@Component({
  selector: 'app-private-tsp-list',
  templateUrl: './private-tsp-list.component.html',
  styleUrls: ['./private-tsp-list.component.scss']
})
export class PrivateTspListComponent implements OnInit {

  searchInput: any;
  tsp: any = [];
  allTsp: any = [];
  allTspDelete:boolean = false;
  public stateFilteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public tspSearchmattag: UntypedFormControl = new UntypedFormControl();
  constructor(
    private snackbar: SnackbarService,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrivateTspListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.allTsp = this.data.allTsp;
    console.log("this.data.allTsp",this.data.allTsp)
    this.tsp = this.allTsp.slice();
    this.searchInput = this._formBuilder.group({
      inputValue: [''],
    });

    this.searchInput.get('inputValue').valueChanges.subscribe(value=> {
      if( value.length >= 1){
        this.tsp = [];
        this.allTsp.forEach(selectedTsp => {
          if(selectedTsp['companyName'].toLowerCase().includes(value.toLowerCase())){
            this.tsp.push(selectedTsp);
          }
        });
      }else{
        this.tsp = this.allTsp.slice();
      }
    });

  }

  cancel() {
    this.dialogRef.close();
  }

  addNewTsps() {
    this.dialogRef.close({ 'addTsp': true });
  }


  deleteTsp(tspData) {
      this.dialogRef.close(
        { 'deleteTsp': true, 'tspData': tspData }
      );
  
  }


}
