import { Component, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-shared-templates',
  templateUrl: './shared-templates.component.html',
  styleUrls: ['./shared-templates.component.scss']
})
export class SharedTemplatesComponent implements OnInit {

  @Input() template: string; 
  @Input() context: any;

  @ViewChild('vahanPopupTemplate', { static: true }) vahanPopupTemplate!: TemplateRef<any>;
  private vahanPopupViewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef
  ){ }

  ngOnInit(){}

  ngOnChanges(changes) {
    setTimeout(() => { // update value on value changes
      this.assignTemplate();
    }, 100);
  }

  // checkIfDataHasBeenUpdate(changes){
  //   switch(this.template){
  //     case 'vahanDetailsTemplate':
  //       if (changes['context'] && changes['context'].currentValue.vahanDetails !== changes['context'].previousValue?.vahanDetails) {
  //         this.assignTemplate();
  //       }
  //       break;
  //   }
  // }

  assignTemplate(){
    switch(this.template){
      case 'vahanDetailsTemplate':
        if (this.vahanPopupViewRef) {
          // Find the index of the existing view and remove it
          const index = this.viewContainerRef.indexOf(this.vahanPopupViewRef);
          if (index !== -1) {
            this.viewContainerRef.remove(index);
          }
        }
        // Create a new embedded view and store its reference
        this.vahanPopupViewRef = this.viewContainerRef.createEmbeddedView(this.vahanPopupTemplate, {
          isLoadingData: this.context.isLoadingData,
          vahanData: this.context.vahanDetails
        });
        break;
    }
  }

}
