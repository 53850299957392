import { Component, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UtilService } from '../utils.service';

@Component({
  selector: 'app-shared-templates',
  templateUrl: './shared-templates.component.html',
  styleUrls: ['./shared-templates.component.scss']
})
export class SharedTemplatesComponent implements OnInit {

  @Input() template: string;
  @Input() context: any;

  @ViewChild('vahanPopupTemplate', { static: true }) vahanPopupTemplate!: TemplateRef<any>;
  @ViewChild('receiversFormPopupTemplate', { static: true }) receiversFormPopupTemplate!: TemplateRef<any>;

  private vahanPopupViewRef: EmbeddedViewRef<any> | null = null;
  private receiversFormPopupRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private utilService: UtilService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes) {
    setTimeout(() => { // update value on value changes
      this.assignTemplate();
    }, 100);
  }

  assignTemplate() {
    switch (this.template) {
      case 'vahanDetailsTemplate':
        if (this.vahanPopupViewRef) {
          // Find the index of the existing view and remove it
          const index = this.viewContainerRef.indexOf(this.vahanPopupViewRef);
          if (index !== -1) {
            this.viewContainerRef.remove(index);
          }
        }
        // Create a new embedded view and store its reference
        this.vahanPopupViewRef = this.viewContainerRef.createEmbeddedView(this.vahanPopupTemplate, {
          isLoadingData: this.context.isLoadingData,
          vahanData: this.context.vahanDetails
        });
        break;
      case 'receiveresFormDetailsTemplate':
        if (this.receiversFormPopupRef) {
          // Find the index of the existing view and remove it
          const index = this.viewContainerRef.indexOf(this.receiversFormPopupRef);
          if (index !== -1) {
            this.viewContainerRef.remove(index);
          }
        }
        
        // Create a new embedded view and store its reference
        this.receiversFormPopupRef = this.viewContainerRef.createEmbeddedView(this.receiversFormPopupTemplate, {
          receiverData: this.context.receiverFormDetails
        });
        break;
    }
  }

  downloadUnloadingInfoImage(document, view) {
    let name = document.documentName;
    let id = document.documentId;
    let key = document.documentKey;
    this.utilService.downloadDoc(name, id, key, view);
  }

}
