import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LoginRedirectService } from './login-redirect/login-redirect.service';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { LoginWithPasswordComponent } from './login-with-password/login-with-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent,
    canActivate :[LoginRedirectService],
    children: [
      {
        path: '',
        canActivate :[LoginRedirectService],
        children: [
          { path: 'login', component: LoginComponent },
          { path: 'signup/verify', component: LoginComponent },
          { path: 'login/verify', component: LoginComponent },
          { path: 'cGFzc3dvcmQ', component: LoginWithPasswordComponent },
          { path: 'forgot-password', component: ResetPasswordComponent },
          { path: 'update-password', component: UpdatePasswordComponent },
          { path: 'under-maintenance', component: UnderMaintenanceComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
