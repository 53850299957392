<div class="form-wrapper">
    <div class="text-data">
        <div class="welcome-data">
            <p class="welcome">Welcome To</p>
            <img class="img-responsive " src="assets/images/ff-logo.png">
            <p class="tag-line">Making Logistics your competitive Advantage!</p>
        </div>
    </div>
    <div class="form-data">
        <div class="form-box">
            <div class="" >
                <div *ngIf="completed" class="loader-div">
                  <div class="row">
        
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
        
                  </div>
                </div>
                <form [formGroup]="loginForm" *ngIf="!completed" (submit)="submitLogin($event)">
                    <div class="login-heading">
                        <p>Login</p>
                    </div>
                  <div class="ff-sm-field login-form top-margin-20" >
                    <mat-form-field class="col-md-12 no-padding height-50">
                      <input matInput class=" placeholder" 
                        placeholder="Enter E-Mail/Mobile Number" formControlName="username">
                    </mat-form-field>
                    <mat-error *ngIf="loginForm.get('username').errors?.required && formSubmitted" class="text-danger">
                      E-Mail/Mobile Number is
                      required</mat-error>
                    <mat-error *ngIf="loginForm.get('username').errors?.pattern && formSubmitted" class="text-danger">
                      E-Mail/Mobile Number is
                      invalid</mat-error>
                  </div>
                  <div class="clearfix"></div>
        
                  <div class="login-form top-margin-20">
                    <mat-form-field class="col-md-12 no-padding height-50">
                      <input matInput [type]="show ? 'text' : 'password'" placeholder="Enter Password"
                        formControlName="password">
                      <span matSuffix class="pass-show-hide pointer" (click)="show = !show" [attr.aria-label]="'Show password'"
                        [attr.aria-pressed]="show">{{!show?'Show':'Hide'}}</span>
                    </mat-form-field>
                    <mat-error *ngIf="loginForm.get('password').errors?.required && formSubmitted" class="text-danger">
                      Password is
                      required</mat-error>
                  </div>

                  <div class="clearfix"></div>
                  <div class="ff_flex otp-verfication-screen"
                    *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'password'">
                    <a class="pointer reset-account" (click)="reset()">Forgot password?</a>
                  </div>

                  <button class="btn login-via-otp-btn top-margin-40"
                    (click)="login()">Login </button>
                  
                </form>
              </div>

        </div>
    </div>
</div>